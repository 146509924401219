import   "./Countdown.css";
import React,{useState} from 'react'
function Countdown () {
    var countTo = new Date("2023-04-30")
    const [day,setDay] = useState(1)
    const [hour,setHour] = useState(1)
    const [minute,setMinutes] = useState(1)
    const [second,setSeconds] = useState(1)

    var DateDiff = {
 
    inDays: function(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();
 
        return Math.floor((t2-t1)/(24*3600*1000));
    },
 
    inHours: function(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();
 
        return parseInt((t2-t1)/(3600*1000));
    },
 
    inMinutes: function(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();
 
        return parseInt((t2-t1)/(60*1000)%60);
    },
 
    inSeconds: function(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();
 
        return parseInt((t2-t1)/(1000)%60);
    }
    }
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }
    function updateTime() {
        var d= new Date()
        setDay(DateDiff.inDays(d,countTo))
        setHour(DateDiff.inDays(d,countTo))
        setMinutes(DateDiff.inMinutes(d,countTo))
        setSeconds(DateDiff.inSeconds(d,countTo))
    }
    function randomTime() {
        var d= new Date()
        setDay(getRandomInt(9))
        setHour(getRandomInt(9))
        setMinutes(getRandomInt(9))
        setSeconds(getRandomInt(9))
    }
    
    React.useEffect (()=>{
        const interval = setInterval(()=> {
            randomTime()
        }, 50)

        return() => {
            clearInterval(interval)
        }
    },[])

    return(
        <div class="dateTimer">
            <div class="dateObject">
                <div class ="value">
                    {day}
                </div>
                <div class ="type">
                    DAYS
                </div>
            </div>
            <div class="dateObject">
                <div class ="value">
                    {hour}
                </div>
                <div class ="type">
                    HOURS
                </div>
            </div>
            <div class="dateObject">
                <div class ="value">
                    {minute}
                </div>
                <div class ="type">
                    MINUTES
                </div>
            </div>
            <div class="dateObject">
                <div class ="value">
                    {second}
                </div>
                <div class ="type">
                    SECONDS
                </div>
            </div>
        </div>

    )
}

export default Countdown;