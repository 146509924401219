import Countdown from './Countdown'
import gif from './assets/santo2.gif'
import './App.css'

export default function App() {
  return (
    <div className='wrapper'>
      <a href='https://www.instagram.com/santowrldwide/'><Countdown /></a>
      <img className = 'logo' src = {gif}/>
    </div>
  );
}
